    .mobile-nav-toggle {
        margin-top: 15px;
    }
    
    .slider-home {
        margin-top: 90px;
        width: 100%!important;
        height: calc(100vh - 90px);
    }
    
    .slider-home img {
        /* min-width: 100vw; */
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }
    
    .section-title {
        margin-top: 20px;
    }
    
    .section-title h2 {
        font-weight: bold;
    }
    
    .icon-services {
        width: 100%;
        height: 100px;
        padding-top: 14px;
        background-color: var(--primaryColor);
        border-radius: 50%;
    }
    
    .icon-services img {
        /* color: red; */
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(183deg) brightness(107%) contrast(101%);
        margin-top: 5px;
        width: 60px;
        height: 60px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }
    
    .about-us .content h2 {
        font-weight: 600;
        text-transform: unset;
    }
    
    .button-primary-a {
        background: var(--primaryColor);
        border: 0;
        padding: 15px 24px;
        color: #fff;
        transition: 0.4s;
        border-radius: 4px;
    }
    
    .button-primary-a:hover,
    .header-button:hover {
        opacity: 0.9;
        color: #fff;
    }
    
    .header-button {
        background: var(--primaryColor);
        border: 0;
        padding: 10px 20px;
        margin-left: 15px;
        color: #fff!important;
        transition: 0.4s;
        border-radius: 4px;
    }
    
    .clients .clients-wrap {
        border: none!important;
    }
    
    .client-logo {
        border-top: 1px solid #ececec!important;
        border-left: 1px solid #ececec!important;
    }
    
    .center-galery-logo {
        display: flex;
        justify-content: center;
    }
    
    #footer {
        padding: 0;
    }
    
    .footer-link {
        color: rgba(255, 255, 255, 0.7);
    }
    
    .footer-link:hover {
        color: var(--secondColor);
        transition: var(--common-transition);
    }
    
    .container-footer-logo {
        display: flex;
        justify-content: center;
    }
    
    .input-newsletter {
        background-color: lightgray;
        border: none;
        border-radius: 3px;
        height: 40px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }
    
    .newsletter-form {
        width: 100%;
        display: flex;
        flex-direction: row;
    }
    
    .newsletter-form button {
        margin: 0;
        height: 40px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    
    .newsletter-form button:hover,
    .newsletter-form button:focus,
    .newsletter-form button:active {
        background-color: var(--secondColor)!important;
    }
    
    .input-newsletter:focus,
    .newsletter-form button:focus,
    .newsletter-form button:active {
        outline: none!important;
        border: none!important;
        box-shadow: none!important;
    }
    
    #footer .footer-newsletter form {
        background: unset;
        margin: 0;
        padding: 0;
    }
    
    .container-footer-logo a {
        margin-left: 0px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    
    .breadcrumbs {
        margin-top: 90px;
    }
    
    .solutions {
        margin-top: 30px;
    }
    
    .solutions-case {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }
    
    .solutions-img {
        /* width: 70%; */
        width: 500px;
        max-width: 60%;
        max-height: 600px;
        max-width: 600px;
    }
    
    .modal-mobile-menu {
        margin-top: 60px;
        max-width: 90%;
        margin-left: 5%;
    }
    
    .modal-mobile-menu .modal-content {
        border-radius: 10px;
        border: none;
        min-height: 300px;
        height: calc(100vh - 100px);
    }
    
    .modal-mobile-menu .header-button {
        margin: 0;
    }
    
    .close-mobile-menu {
        color: #fff;
        z-index: 2000;
        font-size: 40px;
        opacity: 1;
        font-weight: 400;
        position: fixed;
        top: 10px;
        right: 5%;
        border: none;
        outline: unset;
        transition: var(--common-transition)
    }
    
    .close-mobile-menu:hover,
    .close-mobile-menu:active,
    .close-mobile-menu:focus {
        color: #fff;
        opacity: 3;
        border: none;
        outline: none;
    }
    
    .mobile-dropdown button {
        color: var(--primaryColor);
        background-color: unset;
        border: none;
        outline: none;
    }
    
    .mobile-dropdown .dropdown-toggle {
        padding: 0;
        margin-bottom: 10px;
    }
    
    .mobile-dropdown button:hover,
    .mobile-dropdown button:active,
    .mobile-dropdown button:focus {
        background-color: unset!important;
        border: none!important;
        outline: none!important;
        box-shadow: none!important;
        color: var(--secondColor)!important;
    }
    
    .mobile-ancor:hover,
    .mobile-ancor-active {
        color: var(--secondColor)!important;
    }
    
    .container-social-midia {
        width: 90%;
    }
    
    .read-more a:hover {
        background-color: var(--secondColor)!important;
    }
    
    .contact {
        padding: 0;
    }
    
    .contact-link {
        color: unset;
    }
    
    .contact-link:hover {
        color: var(--primaryColor);
    }
    
    .info-wrap {
        margin-bottom: 30px;
    }
    
    .services {
        padding-bottom: 60px;
    }
    
    .telemetry-button,
    .telemetry-button:focus {
        background-color: var(--primaryColor)!important;
        border-color: var(--primaryColor)!important;
        box-shadow: none!important;
    }
    
    .telemetry-button:hover,
    .telemetry-button:active {
        background-color: var(--secondColor)!important;
        border-color: var(--secondColor)!important;
        box-shadow: none!important;
    }
    
    .entry-title {
        font-weight: bold;
        color: #000;
    }
    
    @media screen and (max-width: 768px) {
        .client-logo img {
            -webkit-filter: none!important;
            filter: none!important;
        }
        .center-galery-logo {
            justify-content: flex-start;
        }
        .forms {
            height: 2200px;
        }
    }
    
    @media screen and (max-width: 992px) {
        .solutions-case {
            flex-direction: column;
        }
        .solutions-img {
            max-width: 100%;
        }
    }
    
    @media screen and (max-width: 415px) {
        .forms {
            height: 2700px;
        }
    }